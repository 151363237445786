<template>
    <main class="about">
        <div class="about-main__body">
            <div class="about-main__content">
                <h1 class="about-main__title">Acerca de Jdescuentos Chile</h1>

                <img
                    class="about-main__img"
                    :src="$assets.illustration.aboutUs.jdescuentos.bannerAboutUs1"
                    alt="Jdescuentos banner"
                />

                <p class="about-main__text">
                    <NuxtLink class="about-main__link" to="/">Jdescuentos</NuxtLink>
                    Jdescuentos es el sitio número 1 de cupones de descuento en Chile. Nuestro equipo se
                    encarga de recopilar descuentos en todas las categorías, de esta manera, garantizamos que
                    cada usuario pueda disfrutar del mejor precio en cada una de sus compras en línea.
                    Contamos
                    <NuxtLink class="about-main__link" to="/cupones-descuento"
                        >con más de 50 tiendas con sus respectivos códigos de descuento</NuxtLink
                    >
                    actualizados cada día para que disfrutes del mayor ahorro posible.
                </p>

                <h2 class="about-main__subtitle">Nuestra historia</h2>

                <p class="about-main__text">
                    En el 2021, iniciamos como un sitio web que compartía cupones y ofertas en línea de manera
                    gratuita para todos. Al poco tiempo, nuestra plataforma se hizo popular entre los chilenos
                    que buscan ahorrar dinero, desde pedidos de comida a domicilio en Rappi, Pedidos Ya o Uber
                    Eats, hasta compras en Falabella, Ripley, Nike, adidas, Temu, AliExpress, SHEIN, Amazon y
                    mucho más.
                </p>

                <p class="about-main__text">
                    Este crecimiento nos permitió conectar directamente con las marcas a través de redes de
                    afiliados, razón por la cual, hoy en día ofrecemos numerosos cupones exclusivos, es decir,
                    descuentos diseñados específicamente para nuestros usuarios, los cuales no se encuentran
                    en otro sitio web.
                </p>

                <h2 class="about-main__subtitle">Nuestro propósito</h2>

                <p class="about-main__text">
                    Nuestro propósito es redefinir el ahorro para los chilenos, hacer de cada compra una
                    experiencia gratificante en la que nuestros usuarios siempre reciban el mayor beneficio
                    posible sin gastar demás. Al mismo tiempo, nos impulsa la transparencia, la colaboración y
                    el consumo responsable, valores que están integrados en cada una de nuestras actividades.
                </p>

                <h2 class="about-main__subtitle">¿Cómo funciona Jdescuentos?</h2>

                <p class="about-main__text">
                    Nuestro sitio web está disponible de manera gratuita para todos. Sin necesidad de
                    registros o rellenar formularios. En principio, cada usuario puede explorar los cupones
                    que son tendencia, o bien, revisar tienda por tienda cada código activo y verificado.
                </p>

                <p class="about-main__text">
                    De esta manera, cada cupón publicado en Jdescuentos está respaldado por el trabajo de
                    nuestros colaboradores, quienes se encargan de recopilar, verificar y publicar los
                    descuentos diariamente. Todo esto con la información necesaria sobre términos y
                    condiciones para facilitar el uso de cada código.
                </p>
                <p class="about-main__text">
                    Por otro lado,
                    <NuxtLink class="about-main__link" to="/blog">en nuestro blog</NuxtLink> compartimos
                    información sobre programas de fidelización, descuentos para estudiantes, eventos de
                    ofertas, métodos de pago, consejos de compra, reseñas y todas las recomendaciones
                    necesarias para aprender sobre todos los descuentos disponibles en el mercado chileno.
                </p>

                <img
                    class="about-main__img"
                    :src="$assets.illustration.aboutUs.jdescuentos.bannerAboutUs2"
                    alt="Jdescuentos banner"
                />

                <h2 class="about-main__subtitle">¿Cómo ganamos dinero?</h2>

                <p class="about-main__text">
                    Jdescuentos es un sitio web gratuito, de hecho, puedes aprovechar tantos cupones como
                    quieras. Ahora bien, con el fin de mejorar tu experiencia, en nuestra plataforma no
                    encontrarás anuncios de ningún tipo, por lo tanto, principalmente nuestro
                    <NuxtLink class="about-main__link" to="/blog/general/como-ganamos-dinero-en-jdescuentos"
                        >método de monetización se centra en las redes de afiliados</NuxtLink
                    >.
                </p>

                <p class="about-main__text">
                    En este sentido, algunos cupones están publicados con un enlace de afiliado, de modo que
                    en caso de concretarse la compra, nuestro sitio recibe una pequeña comisión por ello. Sin
                    embargo, este porcentaje no está relacionado con el costo final de la compra.
                </p>

                <h2 class="about-main__subtitle">Nuestro equipo</h2>

                <p class="about-main__text">
                    <a class="about-main__link" href="https://www.hurtadodeals.com/" target="_blank"
                        >Hurtado Deals</a
                    >
                    es la empresa que dirige a Jdescuentos, fundada en el 2013, se ha especializado en el
                    campo de los cupones y descuentos en línea, creando plataformas para comunidades en
                    México, España y, ahora, en Chile. La clave de su éxito es la colaboración con redes de
                    afiliados, lo cual permite conseguir cupones exclusivos, siendo este el principal aporte
                    para nuestra comunidad.
                </p>

                <p class="about-main__text">Ahora bien, nuestro equipo está conformado principalmente por:</p>

                <section class="about-main__team">
                    <article class="team-card">
                        <div class="team-card__info">
                            <img
                                class="info-img"
                                :src="$assets.illustration.aboutUs.jdescuentos.aboutPeople1"
                                alt="Jdescuentos banner"
                            />

                            <div class="info-content">
                                <h3 class="info-content__name">Juanca Hurtado</h3>
                                <h3 class="info-content__job">CEO</h3>
                            </div>
                        </div>

                        <p class="team-card__text">
                            Juanca es el encargado de nuestras estrategias de crecimiento y expansión en el
                            mercado chileno, su colaboración con socios comerciales y redes de afiliados ha
                            sido clave para permitir que nuestros usuarios encuentren descuentos exclusivos.
                            Al mismo tiempo, su trayectoria en el desarrollo de sitios de cupones y ofertas le
                            ha permitido mantener un enfoque innovador al momento de ofrecer la mejor
                            experiencia de usuario en nuestra web.
                        </p>
                    </article>

                    <hr class="about-main__team-line" />

                    <article class="team-card">
                        <div class="team-card__info">
                            <img
                                class="info-img"
                                :src="$assets.illustration.aboutUs.jdescuentos.aboutPeople2"
                                alt="Jdescuentos banner"
                            />

                            <div class="info-content">
                                <h3 class="info-content__name">Andrea Méndez</h3>
                                <h3 class="info-content__job">Editora de contenidos</h3>
                            </div>
                        </div>

                        <p class="team-card__text">
                            Andrea es la encargada de verificar los cupones y ofertas que diariamente se
                            publican en Jdescuentos. Al mismo tiempo, colabora con la creación de contenido
                            útil y relevante para nuestros usuarios, desde guías de ahorro hasta términos y
                            condiciones de un cupón, su trabajo nos permite ofrecer contenido de calidad en
                            cada una de nuestras publicaciones.
                        </p>
                    </article>
                </section>
            </div>
        </div>
    </main>
</template>

<script lang="ts" setup>
const Route = useRoute()

const {
    public: { origin },
} = useRuntimeConfig()

const description =
    'Aprende más sobre Jdescuentos y quién está detrás. Conoce quiénes somos, nuestras actividades y por qué elegirnos.'
const title = 'Quiénes somos | Jdescuentos'

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})
</script>

<style lang="postcss">
.about {
    &-main {
        @apply m-0 mx-auto space-x-2 lg:container md:mb-10 md:mt-4;
        &__body {
            @apply mx-auto my-4 bg-white lg:container md:rounded-3xl lg:max-w-5xl;
        }
        &__content {
            @apply px-6 py-4;
        }

        &__title {
            @apply mb-6 mt-4 text-center text-[28px] font-medium lg:text-[32px];
        }

        &__subtitle {
            @apply mb-4 text-lg lg:text-[28px];
        }

        &__img {
            @apply mb-6 block w-full object-contain md:mb-8 md:h-[500px];
        }

        &__text {
            @apply mb-6 text-base;
        }

        &__link {
            @apply font-semibold underline;
        }

        &__team {
            @apply mt-8 space-y-8;

            &-line {
                @apply w-full bg-gray-200;
            }

            .team-card {
                @apply flex flex-col gap-2 last:mb-4;

                &__info {
                    @apply flex gap-4;

                    .info-img {
                        @apply h-32 w-32 rounded-3xl object-contain;
                    }

                    .info-content {
                        @apply flex flex-col justify-center;

                        &__name {
                            @apply text-lg font-semibold;
                        }

                        &__job {
                            @apply text-base;
                        }

                        &__nav {
                            @apply flex items-center space-x-3;

                            a {
                                @apply underline;
                            }
                        }
                    }
                }

                &__text {
                    @apply col-span-5 row-start-4 text-base;
                }
            }
        }
    }
}
</style>
